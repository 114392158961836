import { render, staticRenderFns } from "./MenuPane.vue?vue&type=template&id=47899c9a&scoped=true"
import script from "./MenuPane.vue?vue&type=script&lang=js"
export * from "./MenuPane.vue?vue&type=script&lang=js"
import style0 from "./MenuPane.vue?vue&type=style&index=0&id=47899c9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47899c9a",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/support.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuPane.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@dundle/locale/data/categories.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuPane.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@dundle/locale/data/countries.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuPane.vue&include=true&external"
if (typeof block2 === 'function') block2(component)
import block3 from "@dundle/locale/data/settings.json?vue&type=custom&index=3&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuPane.vue&include=true&external"
if (typeof block3 === 'function') block3(component)
import block4 from "@dundle/locale/data/cart.json?vue&type=custom&index=4&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuPane.vue&external"
if (typeof block4 === 'function') block4(component)
import block5 from "@dundle/locale/data/buttons.json?vue&type=custom&index=5&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fmenu%2FMenuPane.vue&external"
if (typeof block5 === 'function') block5(component)
import block6 from "./MenuPane.vue?vue&type=custom&index=6&blockType=i18n"
if (typeof block6 === 'function') block6(component)

export default component.exports