<template>
    <div class="x-menu-pane" @click.prevent.stop>
        <UINavBar>
            <transition-group duration="250" :name="currentTransition" mode="out-in" tag="div">
                <div v-if="currentView == 'main'" key="main" class="d-flex little menu-bar-content p-absolute">
                    <UINavIcon name="arrow" circle @click.native.stop.prevent="onClickBack()"></UINavIcon>
                    <UINavLabel class="little" style="padding-left: 0">{{ translate(`title`) }}</UINavLabel>
                </div>

                <div v-if="currentView == 'category'" key="category" class="d-flex little menu-bar-content p-absolute">
                    <UINavIcon name="arrow" circle @click.native.stop.prevent="onClickBack()"></UINavIcon>
                    <UINavLabel style="padding-left: 0">
                        {{ translate(`category.${currentCategory}.name`) }}
                    </UINavLabel>
                </div>
            </transition-group>
        </UINavBar>
        <div class="menu-pane-content" :class="'current-view-' + currentView">
            <div class="main-view">
                <div class="heading">{{ translate('category.name') }}</div>

                <UIRipple
                    v-for="category in categories"
                    :key="category.name"
                    :time-threshold="250"
                    class="category item d-flex justify-content-between"
                    color="black"
                    :opacity="0.1"
                    @click.native.stop.prevent="onClickCategory(category)"
                >
                    <a href="#" class="">
                        {{ translate(`category.${category}.name`) }}
                    </a>
                    <UIIcon :name="['fal', 'chevron-right']" style="height: 2rem"></UIIcon>
                </UIRipple>

                <div class="heading">{{ translate('navigationTitle') }}</div>
                <UIRipple
                    :time-threshold="250"
                    class="item d-flex justify-content-between"
                    color="black"
                    :opacity="0.1"
                    @click.native="onClickCart()"
                >
                    <nuxt-link :to="$locale.path('cart')" class="w-100 d-flex align-items-center">
                        <span style="position: relative" class="menu-icon">
                            <UIIcon name="shopping-cart"></UIIcon>
                            <span v-if="!cartIsEmpty" class="cart-not-empty">&nbsp;</span>
                        </span>
                        <span>{{ translate('cart.title') }}</span>
                    </nuxt-link>
                </UIRipple>
                <UIRipple
                    :time-threshold="250"
                    class="item d-flex justify-content-between"
                    color="black"
                    :opacity="0.1"
                    @click.native="onClickCustomerService()"
                >
                    <nuxt-link :to="$locale.path('support')" class="w-100 d-flex align-items-center">
                        <UIIcon name="headset" class="menu-icon"></UIIcon>
                        {{ translate('support.title') }}
                    </nuxt-link>
                </UIRipple>

                <div class="account">
                    <UIRipple
                        v-if="false"
                        :time-threshold="250"
                        class="item d-flex justify-content-between"
                        color="black"
                        :opacity="0.1"
                        @click.native="onClickAccount()"
                    >
                        <a href="" class="w-100 d-flex align-items-center">
                            <UIIcon name="user-circle" class="menu-icon"></UIIcon>
                            <span>{{ accountLabel }}</span>
                        </a>
                        <UIIcon :name="['fal', 'chevron-right']" style="height: 2rem"></UIIcon>
                    </UIRipple>

                    <UIRipple
                        :time-threshold="250"
                        class="item d-flex justify-content-between"
                        color="black"
                        :opacity="0.1"
                        @click.native.stop.prevent="onClickSettings()"
                    >
                        <a href="#" class="w-100 d-flex align-items-center">
                            <UIFlag :code="selectedRegion" class="menu-icon"></UIFlag>
                            {{ mapLanguage(selectedLanguage) }},&nbsp;
                            <div>{{ selectedCurrency.code }}</div>
                            <div v-if="selectedCurrency.symbol" class="ml-1">({{ selectedCurrency.symbol }})</div>
                        </a>
                        <UIIcon :name="['fal', 'chevron-right']" style="height: 2rem"></UIIcon>
                    </UIRipple>
                </div>
            </div>

            <div ref="content" class="category-view" @touchmove.stop>
                <div v-if="currentView == 'category'">
                    <template v-if="!brandsAreLoading">
                        <div>
                            <UIRipple
                                :time-threshold="250"
                                class="item d-flex justify-content-between"
                                color="black"
                                :opacity="0.1"
                                @click.native="onClickShowAll(currentCategory)"
                            >
                                <nuxt-link :to="$category.getPath(currentCategory)" :width="40" :height="40">{{
                                    translate('button.showAll')
                                }}</nuxt-link>
                            </UIRipple>
                        </div>
                        <div class="select-category item">
                            {{ translate('categories.popularProducts') }}
                        </div>
                        <div v-for="brand in popularBrands[currentCategory]" :key="'popular_' + brand.name">
                            <UIRipple
                                :time-threshold="250"
                                class="brand item d-flex justify-content-between"
                                color="black"
                                :opacity="0.1"
                                @click.native="onClickBrand(brand)"
                            >
                                <nuxt-link :to="$brand.getPath(brand)" :title="$brand.getName(brand)">
                                    <nuxt-resource
                                        :src="getThumbnail(brand)"
                                        lazy
                                        :alt="$brand.getName(brand)"
                                        :width="40"
                                        :height="40"
                                    />
                                    {{ brand.name }}
                                </nuxt-link>
                                <div v-if="isOnSale(brand)" class="sale-label">SALE</div>
                                <div v-if="isNew(brand)" class="new-label">NEW</div>
                            </UIRipple>
                        </div>

                        <div class="select-category item">
                            {{ translate('category.allProducts') }}
                        </div>
                        <div v-for="brand in sortedBrands" :key="brand.name">
                            <UIRipple
                                :time-threshold="250"
                                class="brand item d-flex justify-content-between"
                                color="black"
                                :opacity="0.1"
                                @click.native="onClickBrand(brand)"
                            >
                                <nuxt-link :to="$brand.getPath(brand)" :title="$brand.getName(brand)">
                                    <nuxt-resource
                                        lazy
                                        :src="getThumbnail(brand)"
                                        :alt="$brand.getName(brand)"
                                        :width="40"
                                        :height="40"
                                    />
                                    {{ brand.name }}
                                </nuxt-link>
                                <div v-if="isOnSale(brand)" class="sale-label">SALE</div>
                                <div v-if="isNew(brand)" class="new-label">NEW</div>
                            </UIRipple>
                        </div>
                    </template>
                    <template v-if="brandsAreLoading"> <UISpinner></UISpinner> </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { UINavBar, UINavIcon, UINavLabel, UIIcon, UIRipple, UIFlag, UISpinner } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';
import SettingsMixin from '~/mixins/SettingsMixin';

export default {
    components: {
        UIIcon,
        UIRipple,
        UINavBar,
        UINavIcon,
        UINavLabel,
        UIFlag,
        UISpinner,
    },
    mixins: [SettingsMixin, TranslationMixin],

    data() {
        return {
            currentView: this.$store.getters['ui/menuName'] ? this.$store.getters['ui/menuName'] : 'main',
            currentCategory: '',
            isLoading: false,
            categoryBrands: {},
            popularBrands: {},
        };
    },

    computed: {
        ...mapGetters({
            categories: 'data/getActiveCategories',
            cart: 'cart/getItems',
            menuName: 'ui/menuName',
        }),

        currentTransition() {
            if (this.currentView === 'main') {
                return 'menu-slide-right';
            } else {
                return 'menu-slide-left';
            }
        },

        brandsAreLoading() {
            return this.isLoading;
        },

        sortedBrands() {
            return [...this.categoryBrands[this.currentCategory]].sort((a, b) => a.name.localeCompare(b.name));
        },

        isAuthenticated() {
            return this.$auth.strategy.name === 'account' && this.$auth.loggedIn;
        },

        accountLabel() {
            return this.isAuthenticated ? this.translate('links.myAccount') : this.translate('links.login');
        },

        cartIsEmpty() {
            return this.$store.state.cart.items.length === 0;
        },
    },

    methods: {
        ...mapActions({
            showModal: 'ui/showModal',
        }),
        mapValue(lang) {
            return lang === 'en' ? 'gb' : lang;
        },

        resetView() {
            this.currentView = 'main';
        },

        async setBrands(category) {
            const limit = 24;
            const categoryReponse = await this.$api.brand.getPopularPaginated(category, 0, limit);
            this.categoryBrands[category] = categoryReponse.data;
            this.popularBrands[category] = this.categoryBrands[category].slice(0, 6);
            this.isLoading = false;
        },

        async onClickCategory(category) {
            this.currentCategory = category;
            this.currentView = 'category';
            // get brands
            if (!this.categoryBrands[category]) {
                this.isLoading = true;
                await this.setBrands(category);
            }
        },

        onClickSettings() {
            setTimeout(() => this.showModal('settings'), 301);
            this.$emit('menu:close');
        },

        onClickBrand(brand) {
            this.$router.push(this.$brand.getPath(brand));
        },

        onClickShowAll(category) {
            this.$router.push(this.$category.getPath(category));
        },

        onClickCart() {
            this.$router.push(this.$locale.path('cart'));
        },

        onClickAccount() {
            if (this.isAuthenticated) {
                this.$router.push(this.$locale.path('account'));
            } else {
                setTimeout(() => this.showModal('login'), 301);
                this.$emit('menu:close');
            }
        },

        onClickCustomerService() {
            this.$router.push(this.$locale.path('support'));
        },

        onClickBack() {
            if (this.currentView === 'main') {
                this.$emit('menu:close');
            } else if (['regio', 'language', 'currency'].includes(this.currentView)) {
                this.currentView = 'settings';
            } else {
                this.currentView = 'main';
            }
        },

        isOnSale(brand) {
            return false;
        },

        isNew(brand) {
            return false;
        },

        getThumbnail(brand) {
            return `images/brands/thumbnails/${brand.slug}.svg`;
        },
    },
};
</script>

<style lang="scss" scoped>
.p-absolute {
    position: absolute;
}

.x-menu-pane {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .menu-icon {
        width: 1.3rem;
        margin-right: 0.5rem;
    }

    .cart-not-empty {
        position: absolute;
        top: 0.2rem;
        right: -0.2rem;
        width: 0.6rem;
        height: 0.6rem;
        border: 1px solid white;
        background-color: $color-secondary;
        display: block;
        border-radius: 50%;
    }

    .fa-chevron-right {
        height: 2rem;
        color: $color-header-chevrons;
    }

    .check-icon-checked {
        width: 1rem;
    }

    ::v-deep .x-menu-label,
    ::v-deep .x-menu-icon {
        color: $color-header-text-bold;
    }

    ::v-deep .x-menu-label {
        color: $color-header-text-bold;
        font-weight: bold;
        height: 4rem;
        line-height: 2rem;
    }

    a {
        text-decoration: none;
    }

    .menu-bar-content {
        height: 4rem;
    }

    .menu-pane-content {
        flex: 1;

        position: relative;
        .main-view,
        .category-view,
        .regio-view,
        .language-view,
        .currency-view,
        .settings-view {
            overflow-y: auto;
            -webkit-overflow-scrolling: touch; // Momentum scrolling iOS
            overscroll-behavior: 'contain';

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
            will-change: transform;
        }

        .main-view,
        .category-view,
        .settings-view,
        .regio-view,
        .language-view,
        .currency-view {
            transform: translate(100%, 0);
        }

        &.current-view-main .main-view {
            transform: translate(0%, 0);
        }

        &:not(.current-view-main) .main-view {
            transform: translate(-100%, 0);
        }

        &.current-view-regio,
        &.current-view-language,
        &.current-view-currency {
            .settings-view {
                transform: translate(-100%, 0);
            }
        }

        &.current-view-category .category-view {
            transform: translate(0%, 0);
        }

        &.current-view-settings .settings-view {
            transform: translate(0%, 0);
        }

        &.current-view-regio .regio-view {
            transform: translate(0%, 0);
        }

        &.current-view-language .language-view {
            transform: translate(0%, 0);
        }

        &.current-view-currency .currency-view {
            transform: translate(0%, 0);
        }
    }

    .item,
    .heading {
        display: block;
        height: 3rem;
        padding: 0.5rem 1rem;
        line-height: 2rem;
    }

    .item {
        background: #fff;
    }

    .select-category {
        height: 1.8rem;
        padding: 0.15rem 1rem;
        line-height: 1.5rem;
        font-weight: bold;
        background: $color-grey-3;
        font-size: 0.8rem;
    }

    .account .item:first-child {
        margin-top: 1.5rem;
    }

    .heading {
        padding-left: 1rem;
        padding-bottom: 0rem;

        &:not(:first-child) {
            margin-top: 1.5rem;
        }

        & + .item {
            border-top: 1px solid $color-grey-3;
        }

        height: 1.8rem;
        padding: 0.15rem 1rem;
        line-height: 1.5rem;
    }

    .heading-left,
    .heading {
        font-weight: bold;
        background: $color-grey-3;
        font-size: 0.8rem;
    }

    .heading-right {
        font-weight: normal;
        color: $color-grey-5;
    }

    .regio-view .regio-warning {
        background: $color-warning-bg;
        border: 1px solid $color-warning-border;
        border-radius: 2px;
        padding: 0.6rem;
        margin: 1rem;
        color: $color-warning-text;
        font-size: 0.8rem;
    }

    .item {
        border-bottom: 1px solid $color-grey-3;
        padding-left: 1rem;

        &.selected {
            background: $color-grey-1;
        }
    }

    .brand {
        .nuxt-resource {
            width: 1.5rem;
            height: 1.5em;
            margin-right: 0.5rem;
        }
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .new-label,
    .sale-label {
        color: $color-text-inverted;
        font-size: 0.9rem;
        box-shadow: inset 0 0 2px 0 $color-grey-5;
        text-shadow: 0 0 1px $color-grey-5;
        font-weight: bold;
        border-radius: 2px;
        padding: 0.2rem 0.4rem;
        line-height: 1rem;
        height: fit-content;
    }

    .new-label {
        background-color: $color-validation;
    }

    .sale-label {
        background-image: linear-gradient(-180deg, $color-secondary 0%, $color-secondary 100%);
    }

    .nav-item {
        padding-left: 2rem;
        padding-right: 0.5rem;
        svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    .menu-slide-left-leave-active,
    .menu-slide-left-enter-active {
        transition: transform 0.25s ease-out;
        will-change: transform;
    }

    .menu-slide-left-enter {
        transform: translate(100%, 0);
    }

    .menu-slide-left-leave-to {
        transform: translate(-100%, 0);
    }

    .menu-slide-right-leave-active,
    .menu-slide-right-enter-active {
        transition: transform 0.25s ease-out;
        will-change: transform;
    }

    .menu-slide-right-enter {
        transform: translate(-100%, 0);
    }

    .menu-slide-right-leave-to {
        transform: translate(100%, 0);
    }

    .menu-slide-left-leave-active.little,
    .menu-slide-left-enter-active.little {
        transition: transform 0.25s ease-out, opacity 0.25s ease-out;
        will-change: transform;
    }

    .menu-slide-left-enter.little {
        transform: translate(10%, 0);
        opacity: 0;
    }

    .menu-slide-left-leave-to.little {
        transform: translate(-10%, 0);
        opacity: 0;
    }

    .menu-slide-right-leave-active.little,
    .menu-slide-right-enter-active.little {
        transition: transform 0.25s 0.25 ease-out, opacity 0.25s 0.25 ease-out;
        will-change: transform;
    }

    .menu-slide-right-enter.little {
        transform: translate(-10%, 0);
        opacity: 0;
    }

    .menu-slide-right-leave-to.little {
        transform: translate(10%, 0);
        opacity: 0;
    }
}
</style>

<i18n src="@dundle/locale/data/support.json"></i18n>
<i18n src="@dundle/locale/data/categories.json"></i18n>
<i18n src="@dundle/locale/data/countries.json" include=""></i18n>
<i18n src="@dundle/locale/data/settings.json" include=""></i18n>
<i18n src="@dundle/locale/data/cart.json"></i18n>
<i18n src="@dundle/locale/data/buttons.json"></i18n>
<i18n></i18n>
